import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, email, maxLength, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'sing-in',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        name: '',
        phone: '',
        promocode: '',
        utm_medium: '',
        utm_source: '',
      },
      isValidPhone: false,
      validationErrors: {
        phone: []
      }
    }
  },
  components: {},
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      phone: {
        required,
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      promocode: {
        maxLength: maxLength(100)
      },
    }
  },
  computed: {
    ...mapGetters({
      config: 'setting/config',
      authLoading: 'auth/authLoading',
      recordLoading: 'event/recordToConsultationLoading',
      recordLoadingLanding: 'landing/recordToConsultationLoading',
      recordPopupContent: 'event/recordEventPopup',
      download: 'event/download',
      discount: 'event/discount',
      user: 'profile/user',
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    promocodeErrors() {
      let error = [];
      if (!this.$v.payload.promocode.$dirty) {
        return error;
      }
      if (!this.$v.payload.promocode.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 100));
      }
      if (this.validationErrors.promocode) {
        this.validationErrors.promocode.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  created() {
    if (this.$route.path.includes('/promo/') || this.user) {
      this.setData();
    }

    this.setUtm();
  },
  methods: {
    ...mapActions({
      singIn: `auth/GET_TOKEN`,
      fetchUser: `profile/FETCH_USER_DATA`,
      recordToConsultation: `event/RECORD_TO_CONSULTATION`,
      recordToConsultationLanding: `landing/RECORD_TO_CONSULTATION`,
    }),
    ...mapMutations({
      changePopupRestorePasswordEmail: `auth/CHANGE_POPUP_RESTORE_PASSWORD`,
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
      changeShowRecordPopup: `event/CHANGE_SHOW_RECORD_POPUP`,
      changeDownload: `event/CHANGE_DOWNLOAD`,
      changeDiscount: `event/CHANGE_DISCOUNT`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
    }),
    getConfigItem(key) {
      for (let i in this.config) {
        if (this.config[i].key === key) {
          return this.config[i];
        }
      }
    },
    changeRegister() {
      this.changePopupSignIn(false);
      setTimeout(() => {
        this.changePopupRegister(false);
      }, 150)
    },
    changeRestore() {
      this.changePopupSignIn(false);
      setTimeout(() => {
        this.changePopupRestorePasswordEmail(true);
      })
    },
    DownloadFile(fileName) {
      //Set the File URL.
      let url = require('@/assets/static/Bogoslovsky_GUIDE.pdf')

      //Create XMLHTTP Request.
      let req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "blob";
      req.onload = function () {
        //Convert the Byte Data to BLOB object.
        let blob = new Blob([req.response], { type: "application/octetstream" });

        //Check the Browser type and download the File.
        let isIE = false || !!document.documentMode;
        if (isIE) {
          window.navigator.msSaveBlob(blob, 'Bogoslovsky_GUIDE.pdf');
        } else {
          let url = window.URL || window.webkitURL;
          let link = url.createObjectURL(blob);
          let a = document.createElement("a");
          a.setAttribute("download", 'Bogoslovsky_GUIDE.pdf');
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
      req.send();
    },
    DownloadFileSecond(fileName) {
      //Set the File URL.
      let url = require('@/assets/static/IT_Recruiter_GUIDE.pdf')

      //Create XMLHTTP Request.
      let req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "blob";
      req.onload = function () {
        //Convert the Byte Data to BLOB object.
        let blob = new Blob([req.response], { type: "application/octetstream" });

        //Check the Browser type and download the File.
        let isIE = false || !!document.documentMode;
        if (isIE) {
          window.navigator.msSaveBlob(blob, 'IT_Recruiter_GUIDE.pdf');
        } else {
          let url = window.URL || window.webkitURL;
          let link = url.createObjectURL(blob);
          let a = document.createElement("a");
          a.setAttribute("download", 'IT_Recruiter_GUIDE.pdf');
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
      req.send();
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {};
        obj.name = this.payload.name;
        obj.email = this.payload.email;
        obj.promocode = this.payload.promocode;
        obj.phone = this.payload.phone.replace(/[\s.,%()-]/g, '');
        obj.course = this.recordPopupContent.title;
        if (this.payload.utm_medium) {
          obj.utm_medium = this.payload.utm_medium;
          obj.utm_source = this.payload.utm_source;
          obj.site_url = window.location.origin + window.location.pathname;
        }

        if (this.$route.name === 'promo') {
          this.recordToConsultationLanding(obj)
              .then(() => {

                this.changeShowRecordPopup(false);

                this.$nextTick(() => {
                  this.changeContentPopup({
                    text: this.$t('successRecordToEvent'),
                  });
                })
                setTimeout(() => {
                  this.changeShowContentPopup(true);
                }, 200)
              }).catch(error => {
            let formattedMessage = null;

            if (error && error.response) {
              if (error.response.data.message) {
                formattedMessage = error.response.data.message;
              }

              if (error.response.data.error) {
                formattedMessage = error.response.data.error.message;
              }

              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach(errorKey => {
                  error.response.data.errors[errorKey].forEach((line) => {
                    formattedMessage += `\r\n${line}`;
                  });
                });
              }
            }

            formattedMessage && this.$toasted.error(formattedMessage);
          });
        } else {
          this.recordToConsultation(obj)
              .then(() => {
                if (this.download === true) {
                  if(this.$route.params.slug === 'lead-it-recruiter-profession'){
                    this.DownloadFileSecond();
                  }
                  if(this.$route.params.slug === 'how-to-find-business-idea'){
                    this.DownloadFile();
                  }
                  this.changeDownload(false)
                }
                this.changeShowRecordPopup(false);
                this.$nextTick(() => {
                  if(this.$route.params.slug === 'it-recruiter-course-payment-30'){
                    this.changeContentPopup({
                      text: this.$t('successRecordToEventHint'),
                    });
                  }
                  else{
                    this.changeContentPopup({
                      text: this.$t('successRecordToEvent'),
                    });
                  }
                })
                setTimeout(() => {
                  this.changeShowContentPopup(true);
                }, 200)
              }).catch(error => {
            let formattedMessage = null;

            if (error && error.response) {
              if (error.response.data.message) {
                formattedMessage = error.response.data.message;
              }

              if (error.response.data.error) {
                formattedMessage = error.response.data.error.message;
              }

              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach(errorKey => {
                  error.response.data.errors[errorKey].forEach((line) => {
                    formattedMessage += `\r\n${line}`;
                  });
                });
              }
            }

            formattedMessage && this.$toasted.error(formattedMessage);
          });
        }
      }
    },
    setData() {
      if (this.user) {
        this.payload.name = this.user.first_name;
        this.payload.email = this.user.email;
      }
    },
    changePhoneInput(number, info) {
      this.isValidPhone = info.valid;
      if (this.isValidPhone) {
        this.payload.phone = info.number.e164.replace('+', '');
        this.validationErrors.phone = [];
      }
    },
    changeValidationPhone() {
      this.validationErrors.phone = [];
      if (this.payload.phone.length > 0) {
        if (!this.isValidPhone) {
          this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
        } else {
          this.validationErrors.phone = [];
        }
      } else {
        this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
      }
    },
    setUtm() {
      this.payload.utm_medium = this.$route.query.utm_medium;
      this.payload.utm_source = this.$route.query.utm_source;
    }
  }
}
